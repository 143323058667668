<template>
  <img class="bgd" src="@/assets/background2.jpg" alt="" />
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo03"
        aria-controls="navbarTogglerDemo03"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <router-link class="navbar-brand me-1" to="/">
        <img
          src="@/assets/images/753logo.png"
          class="img-fluid custom-logo"
          alt=""
          style="margin-left: 10rem;"
        />
      </router-link>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              class="nav-link active mr-4"
              to="/"
              style="font-size: 1.5rem; margin-left:  -5rem"
              >首页
            </router-link>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link" href="#" style="font-size: 1.5rem">免费专区</a> -->
          </li>
          <li class="nav-item">
            <a class="nav-link disabled"></a>
          </li>
        </ul>
        <form class="d-flex" role="search" style="padding-right: 100px">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <button class="niceButton4" type="submit">搜索</button>
        </form>
      </div>
    </div>
  </nav>

  <div class="d-flex row my-1 container-fluid justify-content-evenly">
    <RouterLink class="circle col-4 my-1 text-decoration-none text-dark" :to="`/`">
      <img src="@/assets/n-bg1.jpg" alt="" />
      <svg class="icon me-4" aria-hidden="true">
        <use xlink:href="#icon-ppt"></use>
      </svg>
      <h6 class="iiword">英语PPT</h6>
    </RouterLink>
    <div class="circle col-4 my-1">
      <img src="@/assets/n-bg2.jpg " alt="" />
      <svg class="icon me-4" aria-hidden="true">
        <use xlink:href="#icon-yuwen"></use>
      </svg>
      <h6 class="iiword">语文PPT</h6>
    </div>
    <RouterLink class="circle col-4 my-1 text-decoration-none text-dark" :to="`/games`" >
      <img src="@/assets/n-bg3.jpg" alt="" />
      <svg class="icon me-4" aria-hidden="true">
        <use xlink:href="#icon-shuxue"></use>
      </svg>
      <h6 class="iiword">数学PPT</h6>
    </RouterLink>
    <RouterLink  class="circle col-4 my-1 text-decoration-none text-dark" :to="`/games`" >
      <img src="@/assets/n-bg4.jpg" alt="" />
      <svg class="icon me-2" aria-hidden="true">
        <use xlink:href="#icon-beidanci"></use>
      </svg>
      <h6 class="iiword">课堂游戏</h6>
    </RouterLink>
  </div>

  <link rel="shortcut icon" href="@/assets/ppt.svg" type="image/x-icon" />
</template>

<script>
export default {
  name: "Navbar",
  props: {
    msg: String,
  },
  setup() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
.custom-logo {
  width: 50% !important; /* 强制覆盖 Bootstrap */
  height: auto; /* 保持比例 */
}
.bgd {
  height: 17vh;
  width: 100vw;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.circle {
  width: 150px; /* 圆的宽度 */
  height: 100px; /* 圆的高度 */
  border-radius: 25%; /* 圆的半径 */
  position: relative; /* 相对定位 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  overflow: hidden; /* 超出部分隐藏 */
  flex-direction: column;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.circle img {
  max-width: 100%; /* 图片最大宽度 */
  max-height: 100%; /* 图片最大高度 */
  position: absolute; /* 绝对定位 */
  margin-bottom: 5px;
}

input {
  width: 250px;
  border-radius: 42px;
  border: 2px solid skyblue;
  background: rgb(223, 244, 252);
  transition: 0.3s linear;
  float: right;
}
input:focus {
  width: 300px;
}
.niceButton4 {
  background-color: skyblue;
  border: none;
  border-radius: 12px;
  color: white;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
  -webkit-transition-duration: 0.4s;
}
.niceButton4:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

@font-face {
  font-family: "Tahoma";
  src: url();
  font-weight: "bold";
  font-size: "15px";
}

@media (max-width: 1200px) {
}

.svg {
  display: inline-block;
}
.icon {
  width: 2.5em;
  height: 2.5em;
  /* vertical-align: -0.15em; */
  fill: currentColor;
  overflow: hidden;
  position: relative;
}
.iiword {
  position: relative;
}

@media (min-width: 1200px) {
  .icon {
    width: 3.5em;
    height: 3.5em;
    /* // vertical-align: -0.15em; */
    fill: currentColor;
    overflow: hidden;
    position: relative;
  }
}
</style>
