<template>
  <div class="container-fluid bg-dark mt-4">
    <div class="container-fluid">
      <div class="abc-footer-nav">
        <dl>
          <dt
            class=""
            style="
              font-family: SJSA;
              font-size: 1.2em;
              letter-spacing: 3px;
              color: #cccccc;
            "
          >
            <svg class="icon my-2 me-2" aria-hidden="true">
              <use xlink:href="#icon-lianxiwomen_lianxikefu"></use></svg
            >联系我们
          </dt>
          <dd class="abcdd-1">
            <p
              style="
                margin: 0.1em;
                font-family: SJSA;
                font-size: 1.2em;
                color: #cccccc;
                letter-spacing: 3px;
              "
            >
              微信号
            </p>
            <img
              src="../assets/wechat.jpg"
              style="border-radius: 5px !important"
              class="img-fluid abc-img"
              alt=""
              width="80% "
              height="80% "
            />
          </dd>
        </dl>
        <div class="abc-footer-right">
          <div
            style="font-size: 1em; letter-spacing: 3px; color: #cccccc"
            class="d-flex"
          >
            关于我们
          </div>

          <div
            style="
              font-size: 0.7em;
              letter-spacing: 3px;
              color: #cccccc;
              margin-top: 2em;
            "
            class=""
          >
            753原创PPT课件倾力打造优秀PPT交流平台。
            免费资料在免费专区，请电脑登录自行下载，非免费课件请联系客服购买。
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2" style="text-align: center">
      <a
        href="https://beian.miit.gov.cn/"
        style="color: #ffffff; font-size: 0.5rem"
        target="_blank"
        >粤ICP备2023020050号-1</a
      >
      <hr />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style scoped>
.icon {
  width: 2.5em;
  height: 2.5em;
  fill: currentColor;
  padding-left: 0.5em;
}
@media (min-width: 1200px) {
  .container-fluid {
    margin-top: 40px;
    background-color: #393939;
    flex-shrink: 0;
  }
  .abc-footer-nav {
    height: 220px;
    display: flex;
    margin-left: 350px;
    margin-right: 350px;
  }
  dl {
    display: flex;
    flex-wrap: nowrap !important;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    margin-bottom: 0px;
  }
  dt {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    margin-bottom: 0px;
    flex-wrap: wrap !important;
    flex-shrink: 0;
  }
  .abcdd-1 {
    width: 180px;
    margin-left: 30px;
  }
  dd {
    display: flex;
    flex-shrink: 0;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-wrap: wrap !important;
    padding: 5px;
  }
  .abc-footer-right {
    margin-left: 300px;
    margin-top: 30px;
  }
}
@media (max-width: 1200px) {
  .container-fluid {
    display: flex;
    margin-top: 10px;
    background-color: #393939;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-wrap: wrap;
    padding: 20px;
  }
  .abc-footer-nav {
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    width: 100%;
  }
  dl {
    display: flex;
    flex-direction: column;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    margin-bottom: 15px;
    flex-shrink: 1;
    width: 100%;
  }
  dt {
    display: flex;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    margin-bottom: 0px;
    flex-shrink: 1;
    flex-wrap: wrap;
    width: 100%;
  }
  .abcdd-1 {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
  }
  dd {
    display: flex;
    flex-shrink: 1;
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    flex-wrap: wrap;
    padding: 5px;
  }
  .abc-img {
    flex-shrink: 1;
    margin-top: 10px;
    width: 80%; /* 调整图片宽度 */
    height: auto; /* 保持比例 */
    max-width: 150px; /* 最大宽度限制 */
  }
  .abc-footer-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
}
</style>