<template>
  <div class="container-fluid p-0">
    <!-- 标题+边框 -->
    <div class="bg-wheel py-3 position-relative">
      <img :src="headerImage" class="header-image" />
      <h1 class="text-white text-center mb-0" style="font-family: 'SSGLT'">
        幸运大转盘
      </h1>
    </div>

    <!-- 圆盘 -->
    <div class="custom-border">
      <div class="dashed-container position-relative">
        <div id="wheel-container">
          <canvas ref="wheelCanvas" width="400" height="400"></canvas>
        </div>
        <div class="button-group">
          <button @click="startSpin" class="">开始抽奖</button>
          <button class="label-upload" @click="triggerFileInput">
            上传奖品
          </button>
          <button @click="downloadTemplate">下载模板</button>
        </div>
        <input
          type="file"
          ref="fileInput"
          accept=".xlsx"
          @change="handleFile"
          style="display: none"
        />
        <!-- 底部装饰 -->
        <img
          src="@/assets/images/Nezha2.png"
          class="corner-decoration bottom-0 start-0"
        />
        <img
          src="@/assets/images/Nezha2.png"
          class="corner-decoration bottom-0 end-0 image-flip"
        />
      </div>
    </div>
  </div>
  <div v-if="showPrize" class="prize-modal">
    <div class="prize-content">
      <img src="@/assets/icons/Nezha2.ico" alt="icon" />
      <h2>恭喜中奖！</h2>
      <p class="prize-text">{{ prizeResult }}</p>
      <button class="btn btn-confirm" @click="closeModal">确认</button>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, reactive, watch, onMounted } from "vue";
import * as XLSX from 'xlsx';
import localImage from "@/assets/images/Nezha1.png";

// 响应式数据
const prizes = ref([
  "免作业卡",
  "免罚卡",
  "指定座位卡",
  "班长体验卡",
  "棒棒糖",
  "鸡腿",
]);
const colors = ref([
  "#FFB3BA",
  "#FFDFBA",
  "#FFFFBA",
  "#BAFFC9",
  "#BAE1FF",
  "#D0BAFF",
]);
const rotation = ref(0);
const wheelCanvas = ref(null);
const fileInput = ref(null);
const showPrize = ref(false);
const prizeResult = ref("");
const headerImage = ref(localImage);

// 转盘配置
const config = reactive({
  arc: null,
  ctx: null,
  currentRotation: 0,
});

// 初始化画布
onMounted(() => {
  config.ctx = wheelCanvas.value.getContext("2d");
  config.arc = (Math.PI * 2) / prizes.value.length;
  drawWheel();
});

// 监听奖品变化
watch(prizes, () => {
  config.arc = (Math.PI * 2) / prizes.value.length;
  drawWheel();
});

// 绘制转盘
const drawWheel = () => {
  if (!config.ctx) return;

  config.ctx.clearRect(0, 0, 400, 400);

  prizes.value.forEach((text, i) => {
    config.ctx.beginPath();
    config.ctx.fillStyle = colors.value[i % colors.value.length];
    config.ctx.moveTo(200, 200);
    config.ctx.arc(200, 200, 200, i * config.arc, (i + 1) * config.arc);
    config.ctx.fill();

    config.ctx.save();
    config.ctx.translate(200, 200);
    config.ctx.rotate(i * config.arc + config.arc / 2);
    config.ctx.fillStyle = "#000";
    config.ctx.font = "18px Comic Sans MS";
    config.ctx.fillText(text, 120, 10);
    config.ctx.restore();
  });
};

// 开始旋转
// const startSpin = () => {
//   const random = Math.floor(Math.random() * prizes.value.length);
//   const extraRotation = 5 * 360;
//   rotation.value +=
//     extraRotation +
//     ((360 / prizes.value.length) * random - (rotation.value % 360));

//   wheelCanvas.value.style.transform = `rotate(${rotation.value}deg)`;

//   setTimeout(() => {
//     alert(`恭喜获得：${prizes.value[random]}！`);
//   }, 3000);
// };
const startSpin = () => {
  const random = Math.floor(Math.random() * prizes.value.length);
  const extraRotation = 5 * 360;
  rotation.value +=
    extraRotation +
    ((360 / prizes.value.length) * random - (rotation.value % 360));

  wheelCanvas.value.style.transform = `rotate(${rotation.value}deg)`;

  setTimeout(() => {
    prizeResult.value = prizes.value[random];
    showPrize.value = true;
  }, 3000);
};

const closeModal = () => {
  showPrize.value = false;
  prizeResult.value = "";
};

// 文件处理
const triggerFileInput = () => fileInput.value.click();

const handleFile = (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const wb = XLSX.read(data, { type: "array" });
    const ws = wb.Sheets[wb.SheetNames[0]];
    prizes.value = XLSX.utils
      .sheet_to_json(ws, { header: 1 })
      .slice(1)
      .filter((p) => p[0])
      .map((p) => p[0]);
  };

  reader.readAsArrayBuffer(file);
};

// 下载模板
const downloadTemplate = () => {
  const data = [["奖品列表"], ...prizes.value.map((p) => [p])];
  const ws = XLSX.utils.aoa_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "奖品列表");
  const filename = `奖品模板_${new Date().toISOString().slice(0, 10)}.xlsx`;
  XLSX.writeFile(wb, filename);
};
</script>
  
  <style scoped>

@import "@/assets/font/font.css";

.bg-wheel {
  background: #8a030a;
}
.image-flip {
  transform: rotateY(180deg);
}
.upload-btn {
  margin: auto;
  padding: 8px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  z-index: 100;
}

.upload-btn:hover {
  background-color: #45a049;
}
.prize-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s;
}

.prize-content {
  background: white;
  padding: 2rem 3rem;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  animation: scaleUp 0.3s;
}

.prize-text {
  font-size: 1.5rem;
  margin: 1.5rem 0;
  color: #8a171d;
  font-weight: bold;
}

.btn-confirm {
  background: #4caf50;
  color: white;
  padding: 0.8rem 2rem;
  font-size: 1.1rem;
  border-radius: 25px;
  transition: all 0.3s;
}

.btn-confirm:hover {
  background: #45a049;
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

#wheel-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

#wheel-container canvas {
  width: 100%;
  height: auto;
  transition: transform 3s ease-out;
}

/* 框框 */
.custom-border {
  border: 45px solid #8a030a;
  min-height: 600px;
  position: relative;
}
.dashed-container {
  padding-top: 2.5rem;
  padding-bottom: 10.5rem;
  border: 2px dashed #e0e0e0;
  min-height: 600px;
}
.word-card {
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.selected {
  background-color: #c8e6c9 !important;
}
.correct {
  background-color: #c8e6c9 !important;
  border: 2px solid #28a745 !important;
}
.incorrect {
  background-color: #ffcdd2 !important;
  border: 2px solid #8d0311 !important;
}
.corner-decoration {
  position: absolute;
  width: 120px;
  z-index: 100;
}
.header-image {
  width: 120px;
  height: 140px;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 100;
}
.button-group {
  justify-content: center;
  display: flex;
  gap: 10px;
  margin: 20px;
}
button {
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  background: #5bb9f0;
  color: white;
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.2s;
}

button:hover {
  transform: scale(1.1);
  background: #0f5e8b;
}
</style>