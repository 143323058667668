export default {
    namespaced: true,
    state() {
        return {
            items: [
                { id :'1', title: '幸运大转盘', URL: 'https://chuzhonggf.753ppt.cn/%E5%B0%81%E9%9D%A2/3D%E5%8D%A1%E9%80%9A%E9%A3%8E%E6%A0%BC%E5%A4%A7%E8%BD%AC%E7%9B%98%E8%A3%85%E7%BD%AE.jpeg',routercustom:'LuckyJackpot' },
                { id :'2', title: '班级积分统计', URL: 'https://chuzhonggf.753ppt.cn/%E5%B0%81%E9%9D%A2/%E7%8F%AD%E7%BA%A7%E7%A7%AF%E5%88%86%E7%BB%9F%E8%AE%A1.jpeg' ,routercustom:'ClassPL' },

                
            ]
        }
    },

    getters: {
        items: (state) => state.items
    

    },

    mutations: {
      
    },


    actions: {
      
    }

}