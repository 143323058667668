<template>
  <router-view></router-view>

  <div v-if="!$route.meta.hideHeader " class="container d-flex containercus" >
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in items"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/games/${item.routercustom}`">
            <img :src="item.URL" class="card-img-top" alt="..." />
          </RouterLink>

          <div class="card-body">
            <h6 class="card-title">{{ item.title }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import { computed } from "vue";
import { mapGetters } from 'vuex';
import { useStore } from "vuex";

export default {
  name: "Games",
  
  
  setup() {
    const route = useRoute();
    const store = useStore();
    const items = computed(() => store.getters["games/items"]);
    return {items}
  },
};
</script>

<style>
/* 默认样式（大屏幕） */
.containercus {
  min-height: 500px;  
  width: 100%;
  margin: 0 auto;
} 

/* 平板设备 */
@media (max-width: 1024px) {
  .containercus {
    min-height: 400px; /* 平板设备下的高度 */
    width: 90%;
    margin: 0 auto;
    
  }
}

/* 移动设备 */
@media (max-width: 768px) {
  .containercus {
    min-height: 300px; /* 移动设备下的高度 */
    width: 95%;
    margin: 0 auto;
  }
}

/* 小型移动设备 */
@media (max-width: 480px) {
  .containercus {
    min-height: 200px; /* 小型移动设备下的高度 */
    width: 95%;
    margin: 0 auto;
  }
}
</style>