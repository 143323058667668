<template>
  <div class="container" v-if="list[0].list_id">
    <div class="row mb-3">
      <h3
        class="text-left offset-lg-1"
        v-for="(item, i) in $store.getters['product/showlist']"
        :key="i"
      >
        {{ item.subtitle }}
      </h3>
    </div>

    <div class="row">
      <!-- 视频列表部分 -->
      <div class="col-lg-3 col-sm-12 mb-3 videolist order-sm-first">
        <img
          id="jiantou"
          class="picutrecontroller"
          src="@/assets/images/jiantou.png"
          alt=""
          v-show="isShow"
        />
        <br v-show="isShow" />
        <br v-show="isShow" />
        <select
          class="form-select"
          aria-label="Default select example"
          v-model="selected"
          @click="chooseCore"
        >
          <option selected>视频列表</option>
          <option :value="item.list_id" v-for="(item, i) in list" :key="i">
            {{ item.subtitle }}
          </option>
        </select>
      </div>

      <!-- 视频播放器部分 -->
      <div
        class="col-lg-8 col-sm-12 text-center py-3 px-auto border rounded offset-lg-1 mb-3"
        v-for="item in $store.getters['product/showlist']"
        :key="item.video_url2"
      >
        <div class="ratio ratio-16x9">
          <video
            controls
            controlslist="nodownload "
            oncontextmenu="return false;"
            class="embed-responsive-item"
            :poster="`${item.poster ? item.poster : item.picture_url}`"
          >
            <source :src="item.video_url2" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>

  <div class="container" v-else>
    <div class="row mb-3">
      <h3 class="text-left offset-lg-1" v-for="(item, i) in list" :key="i">
        {{ item.title }}
      </h3>
    </div>

    <div class="row">
      <!-- 视频列表部分 -->
      <div class="col-lg-3 col-sm-12 mb-3 videolist order-sm-first">
        <img
          id="jiantou"
          class="picutrecontroller"
          src="@/assets/images/jiantou.png"
          alt=""
          v-show="isShow"
        />
        <br v-show="isShow" />
        <br v-show="isShow" />
        <select
          class="form-select"
          aria-label="Default select example"
          v-model="selected"
          @click="chooseCore"
        >
          <option selected>视频列表</option>
          <option :value="item.list_id" v-for="(item, i) in list" :key="i">
            {{ item.subtitle }}
          </option>
        </select>
      </div>

      <!-- 视频播放器部分 -->
      <div
        class="col-lg-8 col-sm-12 text-center py-3 px-auto border rounded offset-lg-1 mb-3"
        v-for="item in list"
        :key="item.video_url1"
      >
        <div class="ratio ratio-16x9">
          <video
            controls
            controlslist="nodownload "
            oncontextmenu="return false;"
            class="embed-responsive-item"
            :poster="`${item.poster ? item.poster : item.picture_url}`"
          >
            <source :src="item.video_url1" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { findTheProduct } from "@/api/product";
export default {
  name: "Goods",
  setup(props,context) {
    onMounted(() => {});

    const route = useRoute();
    const store = useStore();
    const list = computed(() => {
      return store.state.product.list;
    });

    const chooseCore=  ()=> {
       isShow.value=false;
    };

    const isShow=ref(true);

    watch(
      () => route.params.id,
      (newVal) => {
        if (newVal && `/product/${newVal}` === route.path)
          findTheProduct(route.params.id).then((res) => {
            nextTick(() => {
              return (store.state.product.list = ref(res.data.list));
            });
          });
      },
      { immediate: true }
    );

    const selected = ref("视频列表");

    watch(selected, (newX) => {
      if (newX === "视频列表") {
        return;
      }
      store.commit("product/chooseVideo", newX), { immediate: true };
    });

    return { list, selected,chooseCore,isShow };
  },
};
</script>


<style>
/* .card:hover {
  box-shadow: 3px 3px 10px 0 #4c504f, -3px -3px 10px 0 #4c504f;
}
#jiantou{
  margin:0 auto;
  height: 50px;
  text-align:center;
}
.videolist{
  position:relative;
}

.picutrecontroller {
 
    position:absolute;
    animation: example 1s infinite linear;
    left: 2em;

}
@keyframes example {
    0% {transform: translateY(0);}
    25% {transform: translateY(-10px);}
    50% {transform: translateY(0);}
    75% {transform: translateY(10px);}
    100% {transform: translateY(0);}
}
.container {
  margin: 0 auto !important;
} */
.card:hover {
  box-shadow: 3px 3px 10px 0 #4c504f, -3px -3px 10px 0 #4c504f;
}
#jiantou {
  margin: 0 auto;
  height: 50px;
  text-align: center;
}
.videolist {
  position: relative;
}

.picutrecontroller {
  position: absolute;
  animation: example 1s infinite linear;
  left: 2em;
}
@keyframes example {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.container {
  margin: 0 auto !important;
}

/* 确保在桌面端 .videolist 靠左 */
@media (min-width: 992px) {
  .videolist {
    margin-left: 0 !important;
  }
}
</style>