<template>
  <div class="container">
    <h2 class="text-left mt-3">中小学语法</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/gflist']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>
          <!-- <button type="button" class="btn btn-light b d-none d-md-block d-lg-block" >详细介绍</button> -->
          <!-- 设置了响应式隐藏按钮d-none d-md-block d-lg-block -->
          <div class="card-body">
            <h6 class="card-title">{{ item.title }}</h6>
          </div>
        </div>
      </div>
    </div>

    <h2 class="text-left mt-3">人教版新目标</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/peplist']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>

          <div class="card-body">
            <h6 class="card-title">
              {{ item.title }}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <h2 class="text-left mt-3">同步教材课件</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/otherbook']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>

          <div class="card-body">
            <h6 class="card-title">
              {{ item.title }}
            </h6>
          </div>
        </div>
      </div>
    </div>

    <h2 class="text-left mt-3">音标&绘本阅读</h2>
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/huibenbook']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>

          <div class="card-body">
            <h6 class="card-title">
              {{ item.title }}
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="d-flex justify-content-center m-3">
    <router-link class="" to="/" style="text-decoration: none !important">
      <div class="kk-1 kk-1bg">
        <h4>免费课件</h4>
      </div>
    </router-link>
  </div> -->

  <!-- <div class="container">
    <div class="row">
      <div
        class="col col-md-3 col-sm-6 col-6 p-3"
        v-for="item in $store.getters['home/peplist']"
        :key="item.id"
      >
        <div class="card h-100">
          <RouterLink :to="`/assort/${item.parent_id}`" style="color:black ; text-decoration: none;">
            <img src="item.picture_url" class="card-img-top" alt=""
          />
          <div class="card-body">
            <h5 class="card-title"> {{ item.title }}</h5>
          </div></RouterLink>
        </div>
      </div>
    </div>
  </div> -->
  <div class="container mt-1">
    <div class="d-flex flex-column align-items-center" >
      <h1 class="my-3" style="font-weight: bold">753原创课件PPT</h1>
      <h6 class="my-3" style="font-weight: lighter">
        汇聚大量精品PPT课件文档资源，旨在将用户教学效率提升
      </h6>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row d-flex justify-content-evenly">
      <div
        class="card-botton d-flex text-bg-light border-light m-2 bg-primary bg-opacity-10 flex-column"
      >
        <div class="circle-home circle-one">
          <svg class="icon my-2 me-2" aria-hidden="true">
            <use xlink:href="#icon-tabbar_icon_selected"></use>
          </svg>
        </div>

        <div class="din">
          <h5 style="font-weight: normal; color: rgba(7, 7, 7, 0.685)">
            精品PPT模板
          </h5>
          <h6 style="font-weight: lighter; color: rgba(20, 20, 20, 0.575)">
            不断更新中...
          </h6>
        </div>
      </div>
      <div
        class="card-botton text-bg-light border-light m-2 bg-success bg-opacity-10 flex-column"
      >
        <div class="circle-home circle-two">
          <svg class="icon my-2 me-2" aria-hidden="true">
            <use xlink:href="#icon-yibashoujiangkehuodong"></use>
          </svg>
        </div>

        <div class="din">
          <h5 style="">教师讲课PPT</h5>
          <h6 style="">原创精品</h6>
        </div>
      </div>
      <div
        class="card-botton text-bg-light border-light m-2 bg-warning bg-opacity-10 flex-column"
      >
        <div class="circle-home circle-three">
          <svg class="icon my-2 me-2" aria-hidden="true">
            <use xlink:href="#icon-meihua1"></use>
          </svg>
        </div>
        <div class="din">
          <h5 style="font-weight: normal; color: rgba(7, 7, 7, 0.685)">
            优秀PPT美化团队
          </h5>
          <h6 style="font-weight: lighter; color: rgba(20, 20, 20, 0.575)">
            精心设计
          </h6>
        </div>
      </div>
      <div
        class="card-botton text-bg-light border-light m-2 bg-danger bg-opacity-10 flex-column"
        style="width: 15em; height: 15em"
      >
        <div class="circle-home circle-four">
          <svg class="icon my-2 me-2" aria-hidden="true">
            <use xlink:href="#icon-biaoyang"></use>
          </svg>
        </div>
        <div class="din">
          <h5 style="font-weight: normal; color: rgba(7, 7, 7, 0.685)">
            行业领先品牌
          </h5>
          <h6 style="font-weight: lighter; color: rgba(20, 20, 20, 0.575)">
            遥遥领先
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { findhomeProduct } from "@/api/product";

export default {
  name: "HomePage",

  setup() {
    const store = useStore();
    const route = useRoute();

    const list = computed(() => {
      return store.state.home.list;
    });

    findhomeProduct().then((res) => {
      // console.log(res.data.list)
      return (store.state.home.list = res.data.list);
    });

    return { list };
  },
};
</script>
  
  <style scoped>
@import "@/assets/font/font.css";
.circle-home {
  width: 50%; /* 圆的宽度 */
  height: 45%; /* 圆的高度 */
  border-radius: 48%; /* 圆的半径 */
  position: relative; /* 相对定位 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  overflow: hidden; /* 超出部分隐藏 */
  flex-direction: column;
  display: flex;
  padding: auto;
}
.circle-one {
  background: linear-gradient(#d6e5fc, #b0cfff, #a9c9fa);
}
.circle-two {
  background: linear-gradient(#ddf3e9, #bde9d4, #a5ccbb);
}
.circle-three {
  background: linear-gradient(#fdf5db, #fdf0c6, #e9daab);
}
.circle-four {
  background: linear-gradient(#f7dfe1, #f0cfd3, #e6c4c8);
}
.four-p {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1200px) {
  .kk-1 {
    width: 180px; /* 圆的宽度 */
    height: 60px; /* 圆的高度 */
    position: relative; /* 相对定位 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    overflow: hidden; /* 超出部分隐藏 */
    flex-direction: column;
    display: flex;
    padding: auto;
    /* background-color: #a5ccbb; */
  }
  .kk-1bg {
    background-image: url("@/assets/kk-6.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .kk-1 > h4 {
    text-decoration: none !important;
    color: rgba(7, 7, 7, 0.685);
    font-family: 楷体;
    font-weight: bold;
  }
  .kk-2bg {
    background-image: url("@/assets/kk-3.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .card-botton {
    width: 18em !important;
    height: 18em !important;
    margin: auto !important;
    justify-content: center;
    align-items: center;
    border-radius: 15px !important;
    display: flex;
  }
  .din {
    margin: 10px;
  }
  .din > h5 {
    font-size: 22px;
    font-weight: normal;
    color: rgba(7, 7, 7, 0.685);
    margin-top: 15px;
  }
  .din > h6 {
    font-size: 15px;
    font-weight: lighter;
    color: rgba(20, 20, 20, 0.575);
  }
  .icon {
    width: 3.5em;
    height: 3.5em;
    fill: currentColor;
    padding-left: 0.5em;
  }
}

@media (max-width: 1200px) {
  .kk-1 {
    width: 180px; /* 圆的宽度 */
    height: 60px; /* 圆的高度 */
    position: relative; /* 相对定位 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    overflow: hidden; /* 超出部分隐藏 */
    flex-direction: column;
    display: flex;
    padding: auto;
    /* background-color: #a5ccbb; */
  }

  .kk-1bg {
    background-image: url("@/assets/kk-6.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .kk-1 > h4 {
    text-decoration: none !important;
    color: rgba(7, 7, 7, 0.685);
    font-family: 楷体;
    font-weight: bold;
  }

  .kk-2bg {
    background-image: url("@/assets/kk-2.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .card-botton {
    width: 8em !important;
    height: 8em !important;
    margin: auto !important;
    justify-content: center;
    align-items: center;
    border-radius: 15% !important;
  }

  .din {
    margin: 5px;
  }
  .din > h5 {
    font-size: 10px;
    margin-top: 5px;
    font-weight: normal;
    color: rgba(7, 7, 7, 0.685);
  }
  .din > h6 {
    font-size: 10px;
    font-weight: lighter;
    color: rgba(20, 20, 20, 0.575);
  }
  .icon {
    width: 1.8em;
    height: 1.8em;
    /* vertical-align: -0.15em; */
    fill: currentColor;
    overflow: hidden;
    position: relative;
    margin-left: 0.5em;
  }
}
.card:hover {
  box-shadow: 3px 3px 10px 0 #4c504f, -3px -3px 10px 0 #4c504f;
}

.a {
  position: relative !important;
}
.b {
  position: absolute !important;
  width: 50%;
  height: 20%;
  top: 35%;
  left: 25%;
}
</style>