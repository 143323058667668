<template>
  <div class="container">
    <div class="row">
      <div
        class="col-md-3 col-sm-6 col-6"
        v-for="item in $store.getters['home/gflist']"
        :key="item.id"
      >
        <div class="card a mb-3">
          <RouterLink :to="`/product/category/${item.parent_id}`">
            <img :src="item.picture_url" class="card-img-top" alt="..." />
          </RouterLink>
          <!-- <button type="button" class="btn btn-light b d-none d-md-block d-lg-block" >详细介绍</button> -->
          <!-- 设置了响应式隐藏按钮d-none d-md-block d-lg-block -->
          <div class="card-body">
            <h6 class="card-title">{{ item.title }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",
};
</script>

<style>
</style>