import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "@/assets/font/iconfont"
import "bootstrap"
import "bootstrap/dist/css/bootstrap.css"
import 'jquery'



const app = createApp(App)
document.title = '753原创ppt课件'

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

app.use(store).use(router).mount('#app');

