import { createRouter, createWebHistory } from 'vue-router'
import Layout from '../views/Layout.vue'
import Goods from '../views/goods'
import Home from '../views/home'
import category from '../views/category'
import Search from '../views/search'
import Games from '../views/games/index.vue'
import LuckyJackpot from '../views/games/components/LuckyJackpot.vue'
import ClassPL from '../views/games/components/ClassPL.vue'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      { path: '/', component: Home },
      { path: '/product/:id', component: Goods },
      { path: '/product/category/:id', component: category },
      { path: '/search', component: Search },
      {
        path: '/games', component: Games, meta: { hideHeader: false } ,
          children: [
          { path: 'LuckyJackpot', component: LuckyJackpot ,meta:{ hideHeader: true }},
          { path: 'ClassPL', component: ClassPL ,meta:{ hideHeader: true }},   // 默认子页面，访问 /games 时显示
        
        ]
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
