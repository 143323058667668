<template>
  <head>
    <meta charset="UTF-8" />
    <title>班级积分系统</title>
  </head>
  <div class="container-fluid">
    <!-- 标题+边框 -->
    <div class="bg-wheel py-3 position-relative">
      <img :src="headerImage" class="header-image" />
      <h1 class="text-white text-center mb-0" style="font-family: 'SSGLT'">
        班级积分系统
      </h1>
    </div>
    <div class="custom-border">
      <div class="dashed-container position-relative">
        <div class="text-center mb-4">
          <button class="btn btn-success me-2" @click="sortRanking">
            积分排行榜
          </button>
          <button class="btn btn-primary me-2" @click="addToAll(1)">
            全体+1
          </button>
          <button class="btn btn-warning me-2" @click="addToAll(-1)">
            全体-1
          </button>
          <input
            type="file"
            ref="fileInput"
            hidden
            @change="handleFileUpload"
          />
          <button class="btn btn-info me-2" @click="downloadTemplate">
            下载姓名模板
          </button>
          <button class="btn btn-secondary" @click="$refs.fileInput.click()">
            上传学生姓名
          </button>
        </div>

        <div class="row g-3 justify-content-center">
          <div
            class="col-6 col-md-4 col-lg-3 col-xl-2"
            v-for="student in students"
            :key="student.id"
          >
            <div class="card card-custom shadow-sm">
              <div class="card-body text-center">
                <img
                  src="@/assets/images/Nezha1.png"
                  class="rounded-circle mb-2"
                  style="width: 60px; height: 60px"
                  alt="头像"
                />
                <h5 class="card-title mb-1">学号{{ student.id }}</h5>
                <p class="card-text text-muted small mb-2">
                  {{ student.name || "未命名学生" }}
                </p>
                <div
                  class="d-flex justify-content-center align-items-center mb-2"
                >
                  <button
                    class="btn btn-sm btn-danger me-1"
                    @click="updateScore(student.id, -1)"
                  >
                    -
                  </button>
                  <span class="fs-4 text-primary">{{ student.score }}</span>
                  <button
                    class="btn btn-sm btn-success ms-1"
                    @click="updateScore(student.id, 1)"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import * as XLSX from "xlsx";
import localImage from "@/assets/images/Nezha1.png";

export default {
  setup() {
    const students = reactive([]);
    const fileInput = ref(null);
    const headerImage = ref(localImage);
    const isSortedByScore = ref(false); // 添加一个布尔值状态来跟踪当前的排序方式
    // 初始化学生数据
    const initStudents = () => {
      for (let i = 1; i <= 30; i++) {
        students.push({
          id: i,
          name: "",
          score: 0,
        });
      }
    };

    // 加载本地数据
    const loadScores = () => {
      const saved = localStorage.getItem("studentData");
      if (saved) {
        const data = JSON.parse(saved);
        data.forEach((item, index) => {
          students[index] = { ...students[index], ...item };
        });
      }
    };

    // 保存数据
    const saveData = () => {
      const data = students.map((s) => ({
        id: s.id,
        name: s.name,
        score: s.score,
      }));
      localStorage.setItem("studentData", JSON.stringify(data));
    };

    // 更新分数
    const updateScore = (id, change) => {
      const student = students.find((s) => s.id === id);
      if (student) {
        student.score = Math.max(0, student.score + change);
        saveData();
      }
    };

    // 全体加减分
    const addToAll = (change) => {
      students.forEach((s) => {
        s.score = Math.max(0, s.score + change);
      });
      saveData();
    };

    // 排序
    const sortRanking = () => {
      if (isSortedByScore.value) {
        // 如果当前是按积分排序，则按学号排序
        students.sort((a, b) => a.id - b.id);
        isSortedByScore.value = false;
      } else {
        // 如果当前不是按积分排序，则按积分从高到低排序
        students.sort((a, b) => b.score - a.score);
        isSortedByScore.value = true;
      }
    };

    // 下载模板
    const downloadTemplate = () => {
      const templateData = students.map((s) => ["学号", "姓名"]);
      const ws = XLSX.utils.aoa_to_sheet([
        ["学号", "姓名"],
        ...students.map((s) => [s.id, s.name]),
      ]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "学生名单");
      XLSX.writeFile(wb, "学生姓名模板.xlsx");
    };

    // 处理文件上传
    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        jsonData.slice(1).forEach((row) => {
          const [id, name] = row;
          const student = students.find((s) => s.id === id);
          if (student) student.name = name;
        });

        saveData();
        fileInput.value.value = "";
      };
      reader.readAsArrayBuffer(file);
    };

    onMounted(() => {
      initStudents();
      loadScores();
    });

    return {
      students,
      fileInput,
      updateScore,
      addToAll,
      sortRanking,
      downloadTemplate,
      handleFileUpload,
      headerImage,
    };
  },
};
</script>

<style>
@import " @/assets/font/font.css";

.bg-wheel {
  background: #8a030a;
}
.custom-border {
  border: 45px solid #8a030a;
  min-height: 600px;
  position: relative;
}
.dashed-container {
  padding-top: 2.5rem;
  padding-bottom: 10.5rem;
  border: 2px dashed #e0e0e0;
  min-height: 600px;
}
.card {
  transition: transform 0.2s;
  border-width: 2px;
}
.header-image {
  width: 120px;
  height: 140px;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 100;
}
.corner-decoration {
  position: absolute;
  width: 120px;
  z-index: 100;
}
.image-flip {
  transform: rotateY(180deg);
}

.card:hover {
  transform: translateY(-3px);
}
/* 添加按钮悬停效果 */
.btn {
  transition: transform 0.3s ease;
}

.btn:hover {
  transform: scale(1.1); /* 鼠标悬停时放大 10% */
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
/* 确保每一行的 card 两边有空白 */
.row.g-3.justify-content-center {
  gap: 15px; /* 设置行内元素之间的间距 */
}
.card-custom {
  border: 3px solid #770000 !important;
}
</style>