import axios from 'axios'
// import store from '@/store'



// 导出基准地址，原因：其他地方不是通过axios发请求的地方用上基准地址

const ENV = process.env.NODE_ENV;
// const host =
//   ENV === 'development' ? 'http://127.0.0.1:80' : '175.178.61.79';
const host =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:80'
    : 'https://753ppt.cn'; // 替换为你的域名

const request = axios.create({
  // axios 的一些配置，baseURL  timeout
  baseURL: host,
  timeout: 10000
})

// 请求拦截处理 请求拦截 在请求拦截中可以补充请求相关的配置
// interceptors axios的拦截器对象
request.interceptors.request.use(config => {
  // config 请求的所有信息
  // console.log(config);
  // 响应成功的返回
  return config // 将配置完成的config对象返回出去 如果不返回 请求讲不会进行
}, err => {
  // 请求发生错误时的相关处理 抛出错误
  //  //响应失败的返回
  Promise.reject(err)
})

//响应拦截处理  响应拦截器：包含两个函数（一个是成功返回的函数，一个是失败的返回的函数）
request.interceptors.response.use(res => {
  // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
  // res 是所有相应的信息

  return res.data
}, err => {
  // 服务器响应发生错误时的处理
  Promise.reject(err)
});


// const request = (options) => {
//     if (options.method === 'get') {
//       options.params = options.data;
//     }
//     return instance(options);
//   };

//最后一步 暴露出去实例导出
export default (url, method, submitData) => {
  // 负责发请求：请求地址，请求方式，提交的数据
  return request({
    url,
    method,
    // 1. 如果是get请求  需要使用params来传递submitData   ?a=10&c=10
    // 2. 如果不是get请求  需要使用data来传递submitData   请求体传参
    // [] 设置一个动态的key, 写js表达式，js表达式的执行结果当作KEY
    // method参数：get,Get,GET  转换成小写再来判断
    // 在对象，['params']:submitData ===== params:submitData 这样理解
    [method.toLowerCase() === 'get' ? 'params' : 'data']: submitData
  })
}