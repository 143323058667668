<template>
  
<AppNavbar/>
<router-view></router-view>

  
<appfooter/>
  <!-- 底栏容器 -->
  
  <!-- 底栏容器END -->
</template>

<script>

import AppNavbar from "@/components/library/app-navbar/index.vue"
// import goodlist from "./goodlist/index.vue"
import Appfooter from "@/components/app-footer.vue"


export default {
  name: "Layout",
  components: { Appfooter,AppNavbar },
  // 获取下分类数据
  setup() {
    
  },
};
</script>







<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
  border: 0;
  list-style: none;
}
#app .el-header {
  padding: 0;
}
#app .el-main {
  min-height: 300px;
  padding: 20px 0;
}
#app .el-footer {
  padding: 0;
}
a,
a:hover {
  text-decoration: none;
}
/* 全局CSS END */

/* 顶部导航栏CSS */
.topbar {
  height: 40px;
  background-color: #3d3d3d;
  margin-bottom: 20px;
}
.topbar .nav {
  width: 1225px;
  margin: 0 auto;
}
.topbar .nav ul {
  float: right;
}
.topbar .nav li {
  float: left;
  height: 40px;
  color: #b0b0b0;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  margin-left: 20px;
}
.topbar .nav .sep {
  color: #b0b0b0;
  font-size: 12px;
  margin: 0 5px;
}
.topbar .nav li .el-button {
  color: #b0b0b0;
}
.topbar .nav .el-button:hover {
  color: #fff;
}
.topbar .nav li a {
  color: #b0b0b0;
}
.topbar .nav a:hover {
  color: #fff;
}
.topbar .nav .shopCart {
  width: 120px;
  background: #424242;
}
.topbar .nav .shopCart:hover {
  background: #fff;
}
.topbar .nav .shopCart:hover a {
  color: #ff6700;
}
.topbar .nav .shopCart-full {
  width: 120px;
  background: #ff6700;
}
.topbar .nav .shopCart-full a {
  color: white;
}
/* 顶部导航栏CSS END */

/* 顶栏容器CSS */
.el-header .el-menu {
  max-width: 1225px;
  margin: 1px auto !important;
}
.el-header .logo {
  height: 60px;
  width: 189px;
  float: left;
  margin-right: 100px;
}
.el-header .so {
  margin-left: 200px;
  margin-top: 10px;
  width: 300px;
  float: right;
}

.el-header .so .el-button{
  float: right;
}
/* 顶栏容器CSS END */

/* 底栏容器CSS */
/* 底栏容器CSS END */
</style>
